<template>
<MenuComponent />
  <div class="home-container">
    <div class="container"><h1 class="view-title"> Avaliação Imobiliária</h1></div>
    <div class="cards-container">
      <div class="card" @click="goToForm">
        <card-form />
      </div>
      <div class="card" @click="goToPesquisa">
        <card-pesquisa />
      </div>
      <div class="card" @click="goToConsulta">
        <card-consulta />
      </div>
    </div>
  </div>
</template>

<script>
import MenuComponent from '@/components/layoutComponent.vue/MenuComponent.vue';
import CardConsulta from './CardConsulta.vue';
import CardForm from './CardForm.vue';
import CardPesquisa from './CardPesquisa.vue';

export default {
  components: { CardForm, CardPesquisa, CardConsulta, MenuComponent },
  methods: {
    goToForm() {
      this.$router.push({ name: 'NovoLaudo' }); 
    },
    goToPesquisa() {
      this.$router.push({ name: 'DadosView' }); 
    },
    goToConsulta() {
      this.$router.push({ name: 'Consultarlaudos' }); 
    }
  }
};
</script>

<style scoped>
.home-container {
  text-align: center;
  padding: 50px;
   background-image: url("/public/Fundotela.png");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  min-height: 100vh;
}

.view-title {
  margin-bottom: 20px;
  font-size: 250%;
  
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
}

h1 {
    width: 926px;
    height: 78px;
    color: #177168;
}
.cards-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.card {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}
</style>
