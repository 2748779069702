<template>
<menu-component />
  <div class="table-view">
    <h1 class="view-title">Consultar Imóveis</h1>
    <TableComponent />
  </div>
</template>

<script>
import MenuComponent from '@/components/layoutComponent.vue/MenuComponent.vue';
import TableComponent from '../components/TableComponent.vue';

export default {
  name: 'TableView',
  components: {
    TableComponent,
    MenuComponent,
  }
};
</script>

<style scoped>
.table-view {
  padding: 16px;
  background-image: url("/public/Fundotela.png"); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  height: 100vh; 
  display: flex; 
  flex-direction: column; 
}
h1 {
  font-size: 28px;
  color: #136e66;
  margin-bottom: 20px;
  text-align: left;
}

.view-title {
  text-align: left;
  margin-bottom: 20px;
  font-size: 250%;
  border-bottom: 1px solid #136e66; 
  padding-bottom: 10px; 
  
}

</style>

