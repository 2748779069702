<template>
  <div class="table-container">
    <h1>Nova avaliação imobiliária – Consultar Imóveis</h1>
    <span
      >Etapa 2 - Selecione imóveis amostrais para compor a avaliação
      imobiliária</span
    >
    <div class="controls">
      <input
        type="text"
        id="input-main"
        v-model="cep"
        @input="validateNumber"
        :maxlength="8"
        placeholder="Digite o CEP"
      />
      <button
        class="toggle-button"
        :disabled="!isCEPValid"
        @click="searchByCEP"
      >
        Buscar por CEP
      </button>
      <input
        type="text"
        v-model="filterValue"
        placeholder="Digite para filtrar"
        class="search-input"
      />
    </div>

    <!-- <div class="column-selector">

      <label for="columns">Exibir Colunas:</label>
      <button class="toggle-button" @click="selectAllColumns">
        Selecionar Todas
      </button>
      <button class="toggle-button" @click="unselectAllColumns">
        Desmarcar Todas
      </button>
      <button class="toggle-button" @click="toggleColumnOptions">Opções</button>
      <select
        v-show="showColumnOptions"
        v-model="selectedColumns"
        @change="updateDisplayedColumns"
        id="columns"
        multiple
      >
        <option v-for="column in columns" :key="column.key" :value="column.key">
          {{ column.label }}
        </option>
      </select>
    </div> -->
    <table class="styled-table">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              @change="toggleSelectAll"
              :checked="allSelected"
              :disabled="selectedIds.length >= 5 && !allSelected"
            />
          </th>
          <th
            v-for="column in displayedColumns"
            :key="column.key"
            class="table-header"
            @click="sortBy(column.key)"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredItems" :key="item.index">
          <td>
            <input
              type="checkbox"
              v-model="selectedIds"
              :value="item.index"
              @change="handleSelection(item.index)"
              :disabled="
                selectedIds.length >= 5 && !selectedIds.includes(item.index)
              "
            />
          </td>
          <td v-for="column in displayedColumns" :key="column.key">
            <template v-if="column.key === 'area'">
              {{ formatArea(item[column.key]) }}
            </template>
            <template v-else-if="column.key === 'price'">
              R$ {{ formatCurrency(item[column.key]) }}
            </template>
            <template v-else-if="column.key === 'Preço por (m²)'">
              {{ calcularPrecoPorM2(item.area, item.price) }}
            </template>
            <template v-else-if="column.key === 'link'">
              <a :href="item[column.key]" target="_blank">link</a>
            </template>
            <template v-else>
              {{ item[column.key] }}
            </template>
          </td>
        </tr>
      </tbody>
      <br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br /><br /><br />
    </table>
    <div id="loading"></div>
    <div class="fixed-selected-items">
      <div class="styled-table" v-if="selectedItems.length" style="center">
        <div class="title">
          <h5 style="margin: 10px">Itens Selecionados</h5>
        </div>
        <table
          style="margin: 0 auto; width: 100%; text-align: center"
          class="styled-table"
        >
          <thead id="selectedItensHeader">
            <tr>
              <th class="itemHeader">Endereço</th>
              <th class="itemHeader">Bairro</th>
              <th class="itemHeader">Área (m²)</th>
              <th class="itemHeader">Quartos</th>
              <th class="itemHeader">Banheiros</th>
              <th class="itemHeader">Vagas</th>
              <th class="itemHeader">Preço (R$)</th>
              <th class="itemHeader">Preço por (m²)</th>
              <th class="itemHeader">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr
              id="selectedItens"
              v-for="item in selectedItems"
              :key="item.index"
            >
              <td class="itemData">{{ item.address }}</td>
              <td class="itemData">{{ item.neighbor }}</td>
              <td class="itemData">{{ item.area || "" }}m2</td>
              <td class="itemData">{{ item.room }}</td>
              <td class="itemData">{{ item.bath }}</td>
              <td class="itemData">{{ item.park }}</td>
              <td class="itemData">R${{ formatCurrency(item.price) }}</td>
              <td class="itemData">
                {{ calcularPrecoPorM2(item.area, item.price) }}
              </td>
              <td class="itemData">
                <i
                  class="pi pi-trash action-icon delete-icon"
                  @click="removeSelectedItem(item)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="buttons">
          <button @click="clearSelection" class="buttonL" v-if="!saved">
            Limpar
          </button>
          <button
            v-if="laudoID"
            @click="sendSelectedItems"
            class="buttonS"
            :disabled="loading"
          >
            {{ loading ? "Carregando..." : "Salvar imóveis" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
const estadosBrasil = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

export default {
  name: "TableComponent",
  data() {
    return {
      filterValue: "",
      sortKey: "",
      sortOrder: 1,
      selectedIds: [],
      selectedColumns: [
        "index",
        "address",
        "neighbor",
        "area",
        "room",
        "bath",
        "park",
        "price",
        "Preço por (m²)",
        "link",
      ],
      columns: [
        { key: "index", label: "N° Ordem" },
        { key: "address", label: "Endereço" },
        { key: "neighbor", label: "Bairro" },
        { key: "area", label: "Área (m²)" },
        { key: "room", label: "Quartos" },
        { key: "bath", label: "Banheiros" },
        { key: "park", label: "Vagas" },
        { key: "price", label: "Preço" },
        { key: "Preço por (m²)", label: "Preço por (m²)" },
        { key: "link", label: "Link" },
      ],
      tableItems: [],
      cepInfos: [],
      cities: [],
      states: [],
      loading: false,
      showColumnOptions: false,
      saved: false,
      laudo: {
        valorAvaliacao: "",
        valorM2: "",
        itensSelecionados: [],
      },
      logoPath: "/Texto.png",
      laudoID: null,
      valueCEP: "",
      isCEPValid: false,
      cep: "",
    };
  },

  created() {
    this.laudoID = sessionStorage.getItem("laudoID");
    this.updateDisplayedColumns();
  },
  computed: {
    filteredItems() {
      return this.tableItems
        .filter((item) => {
          return Object.values(item).some((value) => {
            return (
              typeof value === "string" &&
              value.toLowerCase().includes(this.filterValue.toLowerCase())
            );
          });
        })
        .sort((a, b) => {
          if (a[this.sortKey] < b[this.sortKey]) return -1 * this.sortOrder;
          if (a[this.sortKey] > b[this.sortKey]) return 1 * this.sortOrder;
          return 0;
        });
    },
    selectedItems() {
      return this.filteredItems.filter((item) =>
        this.selectedIds.includes(item.index)
      );
    },
    allSelected() {
      return (
        this.filteredItems.length &&
        this.selectedItems.length === this.filteredItems.length
      );
    },
    displayedColumns() {
      return this.columns.filter((column) =>
        this.selectedColumns.includes(column.key)
      );
    },
  },
  methods: {
    backToForm() {
      this.$emit("back");
    },
    calcularPrecoPorM2(area, price) {
      const areaNum = parseFloat(area);
      const priceNum = parseFloat(price);

      if (!isNaN(areaNum) && !isNaN(priceNum) && areaNum > 0) {
        const valorM2 = priceNum / areaNum;
        return this.formatCurrency(valorM2.toFixed(2));
      }
      return "R$ 0,00";
    },
    formatCurrency(value) {
      const numericValue = value.toString().replace(/[^\d.-]/g, "");

      let number = parseFloat(numericValue);
      if (isNaN(number)) return "R$ 0,00";

      return number.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formatArea(area) {
      if (isNaN(area)) return "";

      return `${area} m²`;
    },
    fetchTableItems(estado, cidade, bairro) {
      fetch(
        `${process.env.VUE_APP_API_BASE_URL}/api/items?estado=${estado}&cidade=${cidade}&bairro=${bairro}`,
        {
          headers: {
            "x-access-token": process.env.VUE_APP_API_TOKEN,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao buscar os dados da API");
          }
          return response.json();
        })
        .then((data) => {
          this.toogleLoading("none");
          this.tableItems = data;
        })
        .catch((error) => {
          console.error("Erro ao buscar os dados da API:", error);
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao buscar os dados da API. Por favor, tente novamente.",
          });
        });
    },
    searchByCEP() {
      this.tableItems = [];
      this.toogleLoading("block");

      var cep = document.getElementById("input-main").value;

      fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao buscar os dados da API");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          var estado = this.sanitize_string(estadosBrasil[data["uf"]]);
          var cidade = this.sanitize_string(data["localidade"]);
          var bairro = this.sanitize_string(data["bairro"]);

          this.fetchTableItems(estado, cidade, bairro);
        })
        .catch((error) => {
          console.error("Erro ao buscar os dados da API:", error);
          this.toogleLoading("none");
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao buscar os dados da API. Por favor, verifique o CEP e tente novamente.",
          });
        });
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1;
      } else {
        this.sortKey = key;
        this.sortOrder = 1;
      }
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        if (this.selectedIds.length + this.filteredItems.length > 5) {
          Swal.fire({
            icon: "warning",
            title: "Atenção!",
            text: "Você não pode selecionar mais do que 5 itens.",
            confirmButtonText: "Ok",
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
              }
            },
          });
          event.target.checked = false;
          return;
        }
        this.selectedIds = this.filteredItems.map((item) => item.index);
      } else {
        this.selectedIds = [];
      }
    },
    updateDisplayedColumns() {
      this.displayedColumns = this.columns.filter((column) =>
        this.selectedColumns.includes(column.key)
      );
    },
    toggleColumnOptions() {
      this.showColumnOptions = !this.showColumnOptions;
    },
    selectAllColumns() {
      this.selectedColumns = this.columns.map((column) => column.key);
    },
    unselectAllColumns() {
      this.selectedColumns = [];
    },
    sanitize_string(str) {
      let treatedStr = str.toLowerCase();
      treatedStr = treatedStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      treatedStr = treatedStr.replace(/[^a-z0-9\s]/g, "");
      treatedStr = treatedStr.replace(/\s+/g, "-");
      return treatedStr;
    },
    toogleLoading(option) {
      var loading = document.getElementById("loading");
      loading.style.display = option;
    },
    handleSelection(index) {
      if (this.selectedIds.includes(index) && this.selectedIds.length > 5) {
        Swal.fire({
          icon: "warning",
          title: "Atenção!",
          text: "Você já selecionou o máximo de 5 itens.",
        });
        this.selectedIds = this.selectedIds.filter((id) => id !== index);
      }
      this.$emit("updateSelectedItems", this.selectedIds);
    },
    removeSelectedItem(item) {
      this.selectedIds = this.selectedIds.filter((id) => id !== item.index);
      this.$emit("updateSelectedItems", this.selectedIds);
    },
    clearSelection() {
      if (this.saved) {
        Swal.fire({
          icon: "warning",
          title: "Atenção!",
          text: "Os dados já foram salvos. Não é possível limpar a seleção.",
        });
        return;
      }
      Swal.fire({
        title: "Tem certeza?",
        text: "Tem certeza que deseja limpar a seleção?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, limpar",
        cancelButtonText: "Cancelar",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          const cancelButton = Swal.getCancelButton();

          if (confirmButton) {
            confirmButton.style.cssText = `
        background-color: #FF9364 !important; 
        color: #FAFAFA !important; 
        border: none !important; 
        border-radius: 4px !important;  
        padding: 10px 20px !important;  
        font-size: 16px !important; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
        width: 190px !important; 
        height: 46px !important;  
      `;
          }

          if (cancelButton) {
            cancelButton.style.cssText = `
        background-color: #F4F4F5 !important; 
        color: #18181B !important; 
        border: none !important; 
        border-radius: 4px !important;  
        padding: 10px 20px !important;  
        font-size: 16px !important; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
        width: 190px !important; 
        height: 46px !important;  
      `;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.selectedIds = [];
          this.$emit("updateSelectedItems", this.selectedIds);
          Swal.fire({
            title: "Limpo!",
            text: "A seleção foi limpa com sucesso.",
            icon: "success",
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.cssText = `
            background-color: #136E66 !important; 
            color: #FAFAFA !important; 
            border: none !important; 
            border-radius: 4px !important;  
            padding: 10px 20px !important;  
            font-size: 16px !important; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
            width: 190px !important; 
            height: 46px !important;  
          `;
              }
            },
          });
        }
      });
    },
    sendSelectedItems() {
      this.loading = true;
      const extractPrice = (price) => {
        // Usa expressão regular para manter apenas os números e vírgulas/pontos
        const cleanPrice = price.replace(/[^\d,.]/g, "");
        // Converte para número e garante que seja um valor válido
        return parseFloat(cleanPrice.replace(",", ".")) || 0;
      };
      const formattedData = this.selectedItems.map((item) => ({
        laudo_id: sessionStorage.getItem("laudoID"),
        endereco: item.address,
        total_dormitorios: item.room !== "--" ? item.room : null,
        total_bwc: item.bath !== "--" ? item.bath : null,
        vagas_garagem: item.park !== "--" ? item.park : null,
        valor: extractPrice(item.price),
        area_m2: item.area,
        valor_m2: item.area ? item.price / item.area : 0,
        link_anuncio: item.link,
        html_anuncio: "http://link-anuncio.com.br/teste/teste",
      }));

      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/imoveis`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": process.env.VUE_APP_API_TOKEN,
        },

        body: JSON.stringify(formattedData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao enviar os dados");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Dados enviados com sucesso:", data);
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Dados enviados com sucesso!",
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.cssText = `
          background-color: #136E66 !important; 
          color: #FAFAFA !important; 
          border: none !important; 
          border-radius: 4px !important;  
          padding: 10px 20px !important;  
          font-size: 16px !important; 
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
          width: 190px !important; 
          height: 46px !important;  
        `;
              }
            },
          });
          this.saved = true;
          this.$router.push({ name: "Consultarlaudos" });
        })
        .catch((error) => {
          console.error("Erro ao enviar os dados:", error);
          Swal.fire({
            icon: "warning",
            title: "Atenção!",
            text: "Erro ao enviar os dados. Por favor, tente novamente.",
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.cssText = `
          background-color: #FF9364 !important; 
          color: #FAFAFA !important; 
          border: none !important; 
          border-radius: 4px !important;  
          padding: 10px 20px !important;  
          font-size: 16px !important; 
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
          width: 190px !important; 
          height: 46px !important;  
        `;
              }
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validateNumber(event) {
      this.isCEPValid = this.cep.length === 8 && /^\d+$/.test(this.cep);
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = this.valueCEP;
      } else {
        this.valueCEP = event.target.value;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 28px;
  color: #136e66;
  margin-bottom: 10px;
  text-align: left;
}
span {
  display: block;
  text-align: left;
  margin-bottom: 10px;
  color: #4f4f4f;
  border-bottom: 1px solid #136e66;
  padding-bottom: 10px;
}

.delete-icon {
  color: red;
  cursor: pointer;
}
#loading {
  width: 100%;
  height: 500px;
  background: url("@/assets/loading.gif") no-repeat center center;
  background-size: 5%;
  display: none;
}

.fixed-selected-items {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

#buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.table-container {
  padding: 16px;
  padding: 16px;
  background-image: url("/public/Fundotela.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.toggle-button {
  padding: 8px 16px;
  background-color: #136e66;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #2c7463;
}

.toggle-button:disabled {
  background-color: #136e66;
  color: #ffffff;
  cursor: not-allowed;
}

.toggle-button:disabled:hover {
  background-color: #4b8d7e;
}

.search-input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  flex-grow: 1;
  max-width: auto;
}

.column-selector {
  margin-left: auto;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-selector select {
  max-width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}
.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.styled-table th {
  background-color: #ecfcfb;
  color: #136e66;
  border-top: 1px solid #ddd;
  font-weight: bold;
}

.styled-table th:last-child,
.styled-table td:last-child {
  border-right: none;
}

.styled-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.styled-table tr:hover {
  background-color: #f1f1f1;
}

.styled-table a {
  color: #42b983;
  text-decoration: none;
}

.styled-table a:hover {
  text-decoration: underline;
}

.styled-table th,
.styled-table td {
  padding: 16px 8px;
  font-size: 14px;
}

.styled-table th,
.styled-table td {
  vertical-align: middle;
}

.button {
  padding: 8px 12px;
  background-color: #3b8070; /* Verde */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #2a6d5f;
}

.buttonL {
  padding: 8px 12px;
  background-color: #ffffff;
  color: #0f172a;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonL:hover {
  background-color: #f0f0f0;
}
.buttonS {
  padding: 8px 12px;
  background-color: #136e66;
  color: #ffffff;
  border: 1px solid #3b8070;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonS:hover {
  background-color: #2a6d5f;
}

button:disabled {
  cursor: not-allowed;
}

button {
  margin: 5px;
}
.selected-items-container {
  margin-top: 20px;
}

.selected-items-container h5 {
  font-size: 15px;
  margin-bottom: 10px;
  background-color: #3b8070;
  color: white;
}
.title {
  font-size: 15px;
  margin-bottom: 20px;
  background-color: #3b8070;
  color: white;
  text-align: center;
}

.selected-items-container ul {
  list-style-type: none;
  padding: 0;
}

.selected-items-container li {
  background: #f9f9f9;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.button-container {
  text-align: left;
  grid-column: span 2;
}

.back-button:hover {
  background-color: #356f63;
}
#input-main {
  width: 100%;
  max-width: 250px;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
  margin: 10px 0;
}

#input-main:focus {
  border-color: #3b8070;
  box-shadow: 0 0 5px rgba(59, 128, 112, 0.5);
  outline: none;
}

#selectedItensHeader {
  height: 5px;
}

.itemHeader {
  padding: 5px !important;
}

.itemData {
  padding: 0px !important;
  padding-left: 8px !important;
  font-size: 12px !important;
}

.removeItem {
  height: 15px !important;
  font-size: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
}

.small-button {
  margin: 2px;
  height: 20px;
  display: flex;
  align-items: center;
}
</style>