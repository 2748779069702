<template>
  <div class="card">
    <img :src="require('@/assets/Consulta.png')" alt="Consulta Rápida" class="card-img" />
    <div class="card-content">
      <h1>Consulta Rápida</h1>
      <button class="card-button">Acessar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.card {
  width: 350px; 
  height: 400px;
  background-color: #FFFFFF;
  border-radius: 20px;
  border: 1px solid #C4DDDA;
  opacity: 1; 
  position: relative;
  overflow: hidden; 
  background-size: cover; 
  background-position: center top;
}

.card-img {
  max-width: 100%;
  max-height: 200px; 
  width: auto; 
  height: auto; 
  display: block;
  margin: 0 auto; 
  padding-top: 30px;
}

.card-content {
  text-align: center;
  padding: 16px;
}

.card-content h1 {
  margin: 0;
  color: #136E66;
  font-size: 28px;
}

.card-button {
  margin-top: 16px;
  padding: 15px 40px;
  background-color: #136E66;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px; 
  transition: background-color 0.3s ease;
  display: inline-block; 
  min-width: 250px; 
}

.card-button:hover {
  background-color: #0f4f43;
}
</style>
