<template>
  <MenuComponent />
  <div class="home-container">
    <h1 class="view-title">Avaliações Imobiliárias</h1>
    <table class="report-table">
      <thead>
        <tr>
          <th>Nome do requerente</th>
          <th>CPF do requerente</th>
          <th>Matrícula do Imóvel</th>
          <th>Valor declarado</th>
          <th>Data da Criação</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>{{ report.nome_requerente }}</td>
          <td>{{ report.cpf_requerente }}</td>
          <td>{{ report.matricula }}</td>
          <td>{{ report.valor_avaliacao }}</td>
          <td>{{ report.data_criacao }}</td>
          <td>{{ report.status }}</td>
          <td>
            <div class="icon-container">
              <i
                class="icon edit-icon"
                @click="openEditModal(report.id)"
                title="Editar"
              ></i>
              <i
                class="icon cpf-icon"
                @click="openEditImoveis(report.id)"
                title="Editar imóveis"
              ></i>
              <i
                class="icon valor-icon"
                @click="openValorModal(report.id)"
                title="Arbitrar valor"
              ></i>

              <i
                class="icon delet-icon"
                @click="confirmDelete(report.id)"
                title="Deletar"
              ></i>
              <i
                class="icon custom-icon"
                @click="previewData(report.id)"
                title="Imprimir"
              ></i>
              <i
                class="icon salvar-icon"
                title="Status"
                @click="confirmStatusChange(report.id)"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div id="loading"></div>
    <div>
      <ModalEditarLaudo
        v-if="selectedLaudoId !== null && isLaudoModalVisible"
        :reportId="selectedLaudoId"
        @laudoAtualizado="fetchReports"
        @close="isLaudoModalVisible = false"
      />
      <ModalEditarImoveis
        v-if="selectedImoveisId !== null && isImoveisModalVisible"
        :report-id="selectedImoveisId"
        @close="isImoveisModalVisible = false"
      />
    </div>
    <ModalValorDeclarado
      v-if="showValorModal"
      :reportId="valorModalId"
      @valorAtualizado="fetchReports"
      @close="closeValorModal"
    />
    <div v-if="showAlert" class="custom-alert">
      <div class="alert-header">
        <span class="alert-title">Confirmação</span>
        <i class="pi pi-times close-icon" @click="closeAlert"></i>
      </div>
      <div class="alert-content">
        <p>Tem certeza que deseja deletar este laudo?</p>
        <div class="alert-buttons">
          <button
            class="confirm-button"
            @click="deleteReport(selectedReportsId)"
          >
            Confirmar
          </button>
          <button class="cancel-button" @click="closeAlert">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import ModalValorDeclarado from "./components/ModalValorDeclarado.vue";
import ModalEditarLaudo from "./components/ModalEditarLaudo.vue";
import ModalEditarImoveis from "./components/ModalEditarImoveis.vue";
import MenuComponent from "@/components/layoutComponent.vue/MenuComponent.vue";
import Swal from "sweetalert2";

export default {
  components: {
    ModalEditarLaudo,
    ModalValorDeclarado,
    MenuComponent,
    ModalEditarImoveis,
  },
  data() {
    return {
      reports: [],
      selectedLaudoId: null,
      selectedImoveisId: null,
      isLaudoModalVisible: false,
      isImoveisModalVisible: false,
      selectedReportId: null,
      selectedReportsId: null,
      isEditModalVisible: false,
      showValorModal: false,
      valorModalId: null,
      showAlert: false,
      loading: false,
      activeTab: "form",
      formData: {},
      laudo: {
        valorAvaliacao: "",
        valorM2: "",
        area_m2: "",
        itensSelecionados: [],
      },
      images: {
        fachada: "",
        localizacao: "",
      },
      fiscalId: null,
      userName: "",
    };
  },
  created() {
    this.fiscalId = sessionStorage.getItem("fiscal_id");

    if (this.fiscalId) {
      this.fetchReports();
    } else {
      console.error("fiscal_id não encontrado no sessionStorage");
    }
  },
  methods: {
    async fetchReports() {
      try {
        const res = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudos/fiscal/${this.fiscalId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        if (!res.ok) throw new Error("Erro ao buscar relatórios");
        const data = await res.json();
        this.reports = data;
      } catch (error) {
        console.error("Erro ao buscar relatórios:", error);
      } finally {
        this.toggleLoading("none");
      }
    },
    getUserName() {
      this.userName = sessionStorage.getItem("userName") || "Guest";
    },
    async fetchReport(reportId) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Erro ao obter os detalhes do relatório:", error);
        await Swal.fire({
          title: "Erro",
          text: "Houve um erro ao obter os detalhes do relatório. Por favor, tente novamente.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return null;
      }
    },

    async handleFinalizedReport() {
      await Swal.fire({
        title: "Relatório Finalizado",
        text: "O relatório está finalizado e não pode ser editado.",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok!",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton) {
            confirmButton.style.cssText = `
            background-color: #FF9364 !important; 
            color: #FAFAFA !important; 
            border: none !important; 
            border-radius: 4px !important;  
            padding: 12px 24px !important;  
            font-size: 16px !important; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
            width: auto !important; 
            height: auto !important;  
            display: inline-block; 
            box-sizing: border-box;
          `;
          }
        },
      });
    },

    async openValorModal(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.valorModalId = null;
        this.$nextTick(() => {
          this.valorModalId = reportId;
          this.showValorModal = true;
        });
      }
    },

    closeValorModal() {
      this.showValorModal = false;
      window.location.reload();
    },

    async openEditModal(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.toggleModal("Laudo", reportId);
      }
    },

    async openEditImoveis(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.toggleModal("Imoveis", reportId);
      }
    },

    toggleModal(modalType, reportId) {
      const modalVisibleKey = `is${modalType}ModalVisible`;
      const selectedIdKey = `selected${modalType}Id`;

      this[modalVisibleKey] = false;
      this[selectedIdKey] = null;

      if (this[selectedIdKey] === reportId) {
        this[selectedIdKey] = null;
        this[modalVisibleKey] = false;
      } else {
        this[selectedIdKey] = reportId;
        this[modalVisibleKey] = true;
      }
    },
    async confirmDelete(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.selectedReportsId = reportId;
        const result = await Swal.fire({
          title: "Atenção",
          text: "Tem certeza que deseja deletar este laudo? Essa ação não pode ser desfeita.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deletar",
          cancelButtonText: "Cancelar",
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            const cancelButton = Swal.getCancelButton();

            if (confirmButton) {
              confirmButton.style.cssText = `
                  background-color: #FF9364 !important; 
                  color: #FAFAFA !important; 
                  border: none !important; 
                  border-radius: 4px !important;  
                  padding: 10px 20px !important;  
                  font-size: 16px !important; 
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                  width: 190px !important; 
                  height: 46px !important;  
                `;
            }

            if (cancelButton) {
              cancelButton.style.cssText = `
                  background-color: #F4F4F5 !important; 
                  color: #18181B !important; 
                  border: none !important; 
                  border-radius: 4px !important;  
                  padding: 10px 20px !important;  
                  font-size: 16px !important; 
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                  width: 190px !important; 
                  height: 46px !important;  
                `;
            }
          },
        });

        if (result.isConfirmed) {
          this.deleteReport(this.selectedReportsId);
        }
      }
    },

    async deleteReport(reportId) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );

        if (response.status === 200) {
          console.log("Laudo deletado com sucesso:", response.data);
          await this.fetchReports();
          Swal.fire({
            title: "Deletado!",
            text: "O relatório foi excluído com sucesso.",
            icon: "success",
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.cssText = `
                  background-color: #136E66 !important; 
                  color: #FAFAFA !important; 
                  border: none !important; 
                  border-radius: 4px !important;  
                  padding: 10px 20px !important;  
                  font-size: 16px !important; 
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                  width: 190px !important; 
                  height: 46px !important;  
                `;
              }
            },
          });
        } else {
          throw new Error("Erro ao deletar o relatório");
        }
      } catch (error) {
        console.error("Erro ao deletar laudo:", error);
        Swal.fire("Erro!", "Ocorreu um erro ao excluir o relatório.", "error");
      } finally {
        this.closeAlert();
      }
    },
    formatCurrency(value) {
      if (value === null || value === undefined || isNaN(value)) {
        return "R$ 0,00";
      }

      const strValue = String(value);

      let number = strValue.replace(/\D/g, "");
      number = number.padStart(3, "0");

      const integerPart = number.slice(0, number.length - 2);
      const decimalPart = number.slice(-2);
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      );

      return `R$ ${formattedIntegerPart},${decimalPart}`;
    },
    closeAlert() {
      this.showAlert = false;
      this.selectedReportsId = null;
    },
    toggleLoading(state) {
      const loadingElement = document.getElementById("loading");
      if (state === "block") {
        loadingElement.classList.add("show");
      } else {
        loadingElement.classList.remove("show");
      }
    },
    async loadReportData(reportId) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        const data = await response.json();

        if (data.userEmail) {
          sessionStorage.setItem("userEmail", data.userEmail);
        } else {
          console.warn("Email do usuário não disponível");
        }

        if (data.userName) {
          sessionStorage.setItem("userName", data.userName);
        } else {
          console.warn("Nome do usuário não disponível");
        }

        this.laudo = {
          valorAvaliacao: data.valor_avaliacao,
          valorM2: data.valor_m2,
          area_m2: data.area_m2,
          itensSelecionados: data.itens_selecionados,
          valor_arbitrado: data.valor_arbitrado,
          nome_requerente: data.nome_requerente,
          cpf_requerente: data.cpf_requerente,
          userEmail: sessionStorage.getItem("userEmail"),
          userName: sessionStorage.getItem("userName"),
          userCpf: sessionStorage.getItem("userCpf"),
        };
        console.log("laudo", this.laudo);
        this.formData = {
          nome_requerente: data.nome_requerente,
          cpf_requerente: data.cpf_requerente,
          endereco: data.endereco,
          complemento: data.complemento,
          matricula: data.matricula,
          empreendimento: data.empreendimento,
          uso: data.uso,
          tipologia: data.tipologia,
          condominio: data.condominio,
          andar_pavimento: data.andar_pavimento,
          idade_imovel: data.idade_imovel,
          estado_conservacao: data.estado_conservacao,
          total_dormitorios: data.total_dormitorios,
          total_bwc: data.total_bwc,
          vagas_garagem: data.vagas_garagem,
          valor_avaliacao: data.valor_avaliacao,
          area_m2: data.area_m2,
          valor_m2: data.valor_m2,
          observacoes_adicionais: data.observacoes_adicionais || "",
          fundamento_legal: data.fundamento_legal || "",
          data_criacao: data.data_criacao,
        };
      } catch (error) {
        console.error("Erro ao carregar os dados do laudo:", error);
      }
    },
    confirmStatusChange(reportId) {
      this.reportIdToUpdateStatus = reportId;

      Swal.fire({
        title: "Tem certeza?",
        text: "Tem certeza que deseja mudar o status dessa avaliação para finalizado? Essa ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Finalizar",
        cancelButtonText: "Cancelar",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          const cancelButton = Swal.getCancelButton();

          if (confirmButton) {
            confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
              
            `;
          }

          if (cancelButton) {
            cancelButton.style.cssText = `
              background-color: #F4F4F5 !important; 
              color: #18181B !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusToFinalized(this.reportIdToUpdateStatus);
        }
      });
    },

    async changeStatusToFinalized(reportId) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/concluir/${reportId}`,
          null,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );

        if (response.status === 200) {
          if (response.data.message === "Laudo já está finalizado.") {
            console.log("Laudo já está finalizado:", response.data);
            Swal.fire({
              title: "Já Finalizado!",
              text: "O laudo já está marcado como finalizado.",
              icon: "info",
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) {
                  confirmButton.style.cssText = `
                      background-color: #136E66 !important; 
                      color: #FAFAFA !important; 
                      border: none !important; 
                      border-radius: 4px !important;  
                      padding: 10px 20px !important;  
                      font-size: 16px !important; 
                      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                      width: 190px !important; 
                      height: 46px !important;  
                    `;
                }
              },
            });
          } else {
            console.log(
              "Status do laudo atualizado com sucesso:",
              response.data
            );
            await this.fetchReports();
            Swal.fire({
              title: "Finalizado!",
              text: "O status da avaliação foi atualizado para finalizado.",
              icon: "success",
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) {
                  confirmButton.style.cssText = `
                      background-color: #136E66 !important; 
                      color: #FAFAFA !important; 
                      border: none !important; 
                      border-radius: 4px !important;  
                      padding: 10px 20px !important;  
                      font-size: 16px !important; 
                      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                      width: 190px !important; 
                      height: 46px !important;  
                    `;
                }
              },
            });
          }
        } else {
          throw new Error("Erro ao atualizar o status do laudo");
        }
      } catch (error) {
        console.error("Erro ao atualizar status do laudo:", error);
        Swal.fire("Erro!", "Ocorreu um erro ao atualizar o status.", "error");
      }
    },
    parseCurrency(value) {
      if (!value) return 0;
     
      return parseFloat(value.replace(/[R$.\s]/g, "").replace(",", ".")) || 0;
    },

    async previewData(reportId) {
      this.toggleLoading("block");
      try {
        await this.loadReportData(reportId);

       
        const reportResponse = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        const reportData = await reportResponse.json();

        
        const statusResponse = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        const statusData = await statusResponse.json();

     
        const isFinalizado = statusData.status === "Finalizado"; 

        const imagesResponse = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}/imagens`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        const imagesData = await imagesResponse.json();

        const imgFachada = imagesData.url_img_fachada
          ? `<img src="${imagesData.url_img_fachada}" style="margin-top: 10px; width: 350px; height: 250px; margin-bottom: 10px;" alt="Imagem de Fachada"/>`
          : `<p></p>`;

        const imgLocalizacao = imagesData.url_img_localizacao
          ? `<img src="${imagesData.url_img_localizacao}" style="margin-top: 10px; width: 350px; height: 250px; margin-bottom: 10px; " alt="Imagem de Localização"/>`
          : `<p></p>`;

        const valorArbitrado = this.parseCurrency(
          this.laudo.valor_arbitrado || "0"
        );
        const areaM2 = parseFloat(this.laudo.area_m2) || 1; // Garantir que não seja 0
        const valorM2 = valorArbitrado / areaM2;

        const formattedFormData = Object.entries(this.formData)
          .map(([key, value], index, array) => {
            const formattedKey = key.replace(/_/g, " ").toUpperCase();
            const displayKey = index === 15 ? "VALOR DECLARADO" : formattedKey;
            const isLastFive = index >= array.length - 5;
            return `<tr style="background-color: ${
              isLastFive ? "#ecfcfb" : "#fff"
            };">
                    <th>${displayKey}</th>
                    <td>${value}</td>
                </tr>`;
          })
          .join("");
        const convertDate = (dateString) => {
          const [datePart, timePart] = dateString.split(" ");
          const [day, month, year] = datePart.split("/");
          return `${year}-${month}-${day}T${timePart}`; // Converte para o formato ISO
        };
        // Converter a data
        const dataCriacaoISO = convertDate(this.formData.data_criacao);
        const dataCriacao = new Date(dataCriacaoISO);

        // Verifique se a data é válida
        if (isNaN(dataCriacao)) {
          console.error("Data inválida:", this.formData.data_criacao);
          return; // Saia da função se a data não for válida
        }

        const opcoes = { year: "numeric", month: "long", day: "numeric" };
        const dataFormatada = dataCriacao.toLocaleDateString("pt-BR", opcoes);

        console.log("Data formatada:", dataFormatada);
        const selectedItemsRows = reportData
          .map((item, index) => {
            const valorFormatado = Number(item.valor).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            const valorM2Formatado = Number(item.valor_m2).toLocaleString(
              "pt-BR",
              { style: "currency", currency: "BRL" }
            );

            return `
                <div class="amostras">
                    <h2>Amostra ${index + 1}</h2>
                    <table class="amostras-table">
           <tr>
                            <td>Link do Anuncio</td>
                            <td style="text-align: right;">
                                <a href="${
                                  item.link_anuncio
                                }" target="_blank" rel="noopener noreferrer">
                                    ${item.link_anuncio}
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>Endereço</td>
                            <td style="text-align: right;">${item.endereco}</td>
                        </tr>
                        <tr>
                            <td>Banheiros</td>
                            <td style="text-align: right;">${
                              item.total_bwc
                            }</td>
                        </tr>
                        <tr>
                            <td>Quartos</td>
                            <td style="text-align: right;">${
                              item.total_dormitorios
                            }</td>
                        </tr>
                        <tr>
                            <td>Garagem</td>
                            <td style="text-align: right;">${
                              item.vagas_garagem
                            }</td>
                        </tr>
                        <tr>
                            <td>Preço</td>
                            <td style="text-align: right;">${valorFormatado}</td>
                        </tr>
                        <tr>
                            <td>Valor m²</td>
                            <td style="text-align: right;">${valorM2Formatado}</td>
                        </tr>
                    </table>
                </div>
                
            `;
          })
          .join("");

        const printFrame = document.createElement("iframe");
        printFrame.style.position = "absolute";
        printFrame.style.width = "0px";
        printFrame.style.height = "0px";
        printFrame.style.border = "none";

        document.body.appendChild(printFrame);

        const printDocument =
          printFrame.contentDocument || printFrame.contentWindow.document;

        printDocument.open();
        printDocument.write(`
<!DOCTYPE html>
<html>
<style>


body { margin: 0 }
.sheet-outer {
    margin: 0;
    font-family: "Montserrat", sans-serif;
}
.sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
}

@media screen {
    body { 
        background: #e0e0e0; 
    }
   
    .sheet {
        background: white;
        box-shadow: 0 .5mm 2mm rgba(0,0,0,.3); 
        margin: 5mm auto;
    }
}

.sheet-outer.A4 .sheet { 
    width: 210mm; 
    height: 293mm 
}
.sheet.padding-5mm { padding: 0 5mm 0 5mm}

@page {
    size: A4;
    margin: 0mm;
}
@media print {
    .sheet-outer.A4, .sheet-outer.A5.landscape { 
        width: 210mm;
        height: 293mm 
    }
}
.rascunho {
    position: absolute;
    z-index: 1; /* Alterado para 1 para melhor visibilidade */
    width: 200mm; /* Tamanho desejado */
    top: 0; /* Ajustar a posição */
    left: 0;
}

.rascunho img {
    width: 100%; /* Ajustar conforme necessário */
    height: auto;
}



.header-capa{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  background: #136e66;
  border-radius: 0 0 30px 30px;
}

.header-capa span{
  color: #fff;
  font-size: 12px;
 text-align: right;
 margin: 65px 20px 0 0;
}

img.logo-itbi{
width: 120px;
margin: 20px;
}

.titulo-capa{
  position: relative;
  margin: auto;
  height: 844px;
  vertical-align: middle;
}
.titulo-capa h1{
  position: absolute;
  top: 252px;
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: #D1AE6D;
  margin: 0 0 0 20px;
}


.rodape-capa{
  position: relative;
  height: 115px;
  background: #136e66;
  border-radius: 30px 30px 0 0;
  padding: 30px;
}

.rodape-capa p{
  position: relative;
  font-size: 18px;
  color: #ffffff;
  line-height:25px;
  margin: 0px;
}
.rodape-capa hr{
  color: #fffffff;
  border: 0.5px solid #ffffff;
}
.container-fotos{
  display: flex;
  flex-direction: row;
  gap: 40px;

}

.container-fotos img{
  border-radius: 20px;
  height: 246px; object-fit: cover;
}

.container-fotos figure{
  margin: 0 0 30px 0;
  width: 50%;
}
.container-fotos figcaption{
  font-size: 11px;
}
.conteudo h1{
  font-size: 24px;
  color: #136e66;
  text-transform: uppercase;

}
.caracteristicas{
  
  position: relative;
  background: #eeeeee;
  border-radius: 20px;
  height: 380px;
  padding: 10px;
}

.caracteristicas h2{
  color: #136e66;
  margin: 0;
}
.caracteristicas table{
  font-size: 12px;
  line-height: 17px;
  margin: 0;
  width: 355px;
  margin: 10px 0 0 0;
}
.amostras table td{
border-bottom: 1px solid #ccc;
padding: 5px; 
}
.container-caracteristicas{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.coluna{
  width: 50%;
  font-size: 12px;
}
.coluna h3{
  color: #136e66;
  margin: 0;
}
.coluna p{
  margin: 0 0 20px;
  line-height: 21px;
}
.valor-declarado{
  display: flex;
  flex-direction: row;
  background: #f5f2e7;
  height: 85px;
  border-radius: 20px;
  margin: 15px 0;
  align-items: center;

}

.coluna-valor{
  width: 33.33%;
  text-align: center;
  vertical-align: middle;
}

.coluna-valor p{
  font-size: 12px;
  margin: 3px;
}
.coluna-valor span{
  font-size: 20px;
  font-weight: bold;
}
.final{
  display: flex;
  flex-direction: row;
  margin: 0;
  align-items: center;
  gap: 20px;

}
.valor-final{
  width: 50%;
  color: #ffffff;
  font-size: 12px;
  background: #136e66;
  border-radius: 20px;
  padding: 15px;
}
.valor-final h1{
  color: #ffffff;
  margin: 0;
  text-align: ;
  margin: 5px 0 ;
}
.valor-final p{
  margin: 0;
  text-align: ;
}
.foot-valor-final{
  display: flex;
  flex-direction: row;
  text-align: ;
}
.foot-valor-final span{
  width: 50%;

}
.assinatura{
  width: 50%;
  height: 40px;
}
.assinatura hr{
  width: 100%;
}
.assinatura p {
  align-items: flex-end;
  font-size: 12px;
  margin: 3px;
}
.elementos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.elementos-titulo{
  font-size: 24px;
  color: #136e66;
  text-transform: uppercase;
}
  .elementos .amostras:first-child {
    margin: 0;
}
.amostras {
    width: 356px;
    background: #eeeeee;
    border-radius: 20px;
    padding: 0; 
    margin-bottom: 10px;
    margin-right: 10px; 
}
.amostras table {
    font-size: 12px;
    line-height: 13px;
    width: 100%; 
    margin: 0;
}


.amostras h2 {
    color: #136e66;
    margin: 10px 0; 
    padding: 0;
    font-size: 17px;
}
.simple41{
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.simple41-2{
  position: absolute;
  right: 30px;
  bottom: 20px;

}
.simple41 img, .simple41-2 img{
  width: 80px;
}

</style>


<head>
	<title>Page Title</title>
	<link rel="stylesheet" type="text/css" href="print.css">
	<link rel="preconnect" href="https://fonts.googleapis.com">
	<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
	<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
		rel="stylesheet">
</head>

<body>

	<div class="sheet-outer A4">


		<section class="sheet padding-5mm">
			<article>
         ${
           !isFinalizado
             ? `
          <div class="rascunho">
        		<img src="/Rascunho.png" alt="Rascunho Image">
        	</div> `
             : ``
         }
				<div class="header-capa">
					<img src="/Logo.png" class="logo-itbi">
				</div>

				<div class="titulo-capa">
					<h1>Termo de Arbitramento para fins de ITBI</h1>
				</div>
				<div class="rodape-capa">

					<p><strong></strong><br>
						${this.formData.endereco}<br>
					</p>
					<hr>
        		<div class="simple41-2"><img src="logo-simple-41-2.png"></div>
				</div>
			</article>
		</section>



		<section class="sheet padding-5mm" v-if="isPrinting">
			<article>
         ${
           !isFinalizado
             ? `
          <div class="rascunho">
        		<img src="/Rascunho.png" alt="Rascunho Image">
        	</div> `
             : ``
         }
				<div class="header-capa">
					<img src="/Logo.png" class="logo-itbi">
					<span>
						${dataFormatada}</span>
				</div>

				<div class="conteudo">
					<h1>Termo de Arbitramento para fins de ITBI</h1>
					<div class="container-fotos">
						<figure>
							${imgFachada}
							
						</figure>

						<figure>
							${imgLocalizacao}
	
						</figure>
					</div>

					<div class="caracteristicas">
						<h2>Características do imóvel</h2>
						<div class="container-caracteristicas">
							<div class="coluna">
								<table >
									<thead>
										<tr>
											<th></th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Nome Requerente</td>
											<td style="text-align: right;">${this.formData.nome_requerente}</td>
										</tr>
										<tr>
											<td>CPF Requerente</td>
											<td style="text-align: right;">${this.formData.cpf_requerente}</td>
										</tr>
										<tr>
											<td>Endereço</td>
											<td style="text-align: right;">${this.formData.endereco}</td>
										</tr>
										<tr>
											<td>Complemento</td>
											<td style="text-align: right;">${this.formData.complemento}</td>
										</tr>
										<tr>
											<td>Matrícula</td>
											<td style="text-align: right;">${this.formData.matricula}</td>
										</tr>
										<tr>
											<td>Empreendimento</td>
											<td style="text-align: right;">${this.formData.empreendimento}</td>
										</tr>
										<tr>
											<td>Uso</td>
											<td style="text-align: right;">${this.formData.uso}</td>
										</tr>
										<tr>
											<td>Tipologia</td>
											<td style="text-align: right;">${this.formData.tipologia}</td>
										</tr>
										<tr>
											<td>Condomínio</td>
											<td style="text-align: right;">${this.formData.condominio}</td>
										</tr>
										<tr>
											<td>Andar Pavimento</td>
											<td style="text-align: right;">${this.formData.andar_pavimento}</td>
										</tr>
										<tr>
											<td>Idade Imóvel</td>
											<td style="text-align: right;">${this.formData.idade_imovel}</td>
										</tr>
										<tr>
											<td>Estado Conservação</td>
											<td style="text-align: right;">${this.formData.estado_conservacao}</td>
										</tr>
										<tr>
											<td>Total Dormitórios</td>
											<td style="text-align: right;">${this.formData.total_dormitorios}</td>
										</tr>
										<tr>
											<td>Total BWC</td>
											<td style="text-align: right;">${this.formData.total_bwc}</td>
										</tr>
										<tr>
											<td>Vagas Garagem</td>
											<td style="text-align: right;">${this.formData.vagas_garagem}</td>
										</tr>
									</tbody>
								</table>

							</div>
							<div class="coluna">
								<h3>Observações Adicionais</h3>
								<p>${
                  this.formData.observacoes_adicionais ||
                  "Não há observações adicionais."
                }</p>

								<h3>Fundamento Legal</h3>
								<p>${this.formData.fundamento_legal || "Não especificado."}</p>
							</div>

						</div>
					</div>

					<div class="valor-declarado">
						<div class="coluna-valor">
							<p>Valor declarado pelo conribuinte</p><span>${
                this.formData.valor_avaliacao
              }</span>
						</div>
						<div class="coluna-valor" style="border-left: 2px solid black;border-right: 2px solid black;">
							<p>Área m<sup>2</sup></p><span>${this.formData.area_m2}</span>
						</div>
						<div class="coluna-valor">
							<p>Valor m<sup>2</sup></p><span>${this.formData.valor_m2}</span>
						</div>
					</div>

					<div class="final">
						<div class="valor-final">
							<p>Valor final arbitrado</p>
							<h1>${this.laudo.valor_arbitrado || 0}</h1>
							<div class="foot-valor-final">
								<span>Área m<sup>2</sup><br><strong>${this.laudo.area_m2 || 0}</strong></span>
								<span>Valor do m<sup>2</sup><br><strong>R$ ${valorM2.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</strong></span>

							</div>
						</div>
						<div class="assinatura">
							<br>
							<hr style="margin: 0;">
							<p><strong>${this.laudo.userName}</strong><br>Auditor<br>${this.laudo.userCpf}
							</p>
						</div>
             <div class="simple41"><img src="logo-simple-41.png"></div>
					</div>
			</article>
		</section>


		<section class="sheet padding-5mm">
			<article>
         ${
           !isFinalizado
             ? `
          <div class="rascunho">
        		<img src="/Rascunho.png" alt="Rascunho Image">
        	</div> `
             : ``
         }
				<h1 class="elementos-titulo">Elementos amostrais</h1>
				<div class="elementos">
					<div class="amostras">
						<table class="amostras-table">
             ${selectedItemsRows}
								
       
						</table>
					</div>
          <div class="simple41"><img src="logo-simple-41.png"></div>
				</div>
			</article>
		</section>

	</div>


</body>

</html>



    `);
        printDocument.close();

        setTimeout(() => {
          printFrame.contentWindow.focus();
          printFrame.contentWindow.print();

          const loadingTimeout = setTimeout(() => {
            this.toggleLoading("none");
            document.body.removeChild(printFrame);
          }, 500);

          printFrame.contentWindow.addEventListener("beforeunload", () => {
            clearTimeout(loadingTimeout);
            this.toggleLoading("none");
            document.body.removeChild(printFrame);
          });

          printFrame.addEventListener("afterprint", () => {
            clearTimeout(loadingTimeout);
            this.toggleLoading("none");
            document.body.removeChild(printFrame);
          });
        }, 3000);
      } catch (error) {
        console.error("Erro ao gerar a visualização do relatório:", error);
        this.toggleLoading("none");
      }
    },
  },
};
</script>

<style scoped>
#loading {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90px;
  height: 90px;
  background: url("../../assets/loading.gif") no-repeat center center;
  background-size: contain;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

#loading.show {
  display: block;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.icon:hover {
  opacity: 0.8;
}

.edit-icon {
  --icon-url: url("@/assets/icons/create.png");
}

.delet-icon {
  --icon-url: url("@/assets/icons/delete.png");
  width: 18px; /* Ajuste a largura do ícone */
  height: 18px; /* Ajuste a altura do ícone */
  background-size: contain; /* Ajusta o tamanho da imagem de fundo para caber dentro do elemento */
  background-repeat: no-repeat; /* Evita a repetição da imagem de fundo */
  background-position: center; /* Centraliza a imagem de fundo */
  display: inline-block; /* Garante que o elemento se comporte como um bloco inline */
}

.salvar-icon {
  --icon-url: url("@/assets/icons/salvar.png");
}

.valor-icon {
  --icon-url: url("@/assets/icons/money.png");
}

.cpf-icon {
  --icon-url: url("@/assets/icons/Vector.png");
  width: 18px; /* Ajuste a largura do ícone */
  height: 18px; /* Ajuste a altura do ícone */
  background-size: contain; /* Ajusta o tamanho da imagem de fundo para caber dentro do elemento */
  background-repeat: no-repeat; /* Evita a repetição da imagem de fundo */
  background-position: center; /* Centraliza a imagem de fundo */
  display: inline-block; /* Garante que o elemento se comporte como um bloco inline */
}
.custom-icon {
  --icon-url: url("@/assets/icons/impressora.png");
  width: 18px; /* Ajuste a largura do ícone */
  height: 18px; /* Ajuste a altura do ícone */
  background-size: contain; /* Ajusta o tamanho da imagem de fundo para caber dentro do elemento */
  background-repeat: no-repeat; /* Evita a repetição da imagem de fundo */
  background-position: center; /* Centraliza a imagem de fundo */
  display: inline-block; /* Garante que o elemento se comporte como um bloco inline */
}

.icon {
  background-image: var(--icon-url);
}

.home-container {
  padding: 20px;
  background-image: url("/public/Fundotela.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 94vh;
  padding: 15px;
  box-sizing: border-box;
}

.view-title {
  text-align: left;
  margin-bottom: 20px;
  font-size: 250%;
  color: #136e66;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.report-table th,
.report-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.report-table th {
  background-color: #ecfcfb;
  color: black;
}

.report-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.report-table tr:hover {
  background-color: #ddd;
}

.report-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #ecfcfb;
  color: #136e66;
}

.action-icon {
  margin-right: 10px;
}

.edit-icon {
  color: green;
  cursor: pointer;
}

.delete-icon {
  color: red;
  cursor: pointer;
}

button {
  background-color: #3b8070;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #2c7463;
}

.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: white;
  border: 1px solid #3b8070;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
}

.alert-header {
  background-color: #3b8070;
  color: white;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-title {
  font-weight: bold;
  font-size: 18px;
}

.close-icon {
  cursor: pointer;
}

.alert-content {
  padding: 10px;
}

.alert-content p {
  margin: 0;
}

.alert-buttons {
  text-align: right;
  margin-top: 10px;
}

.confirm-button,
.cancel-button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 10px;
}

.confirm-button {
  background-color: #3b8070;
  color: white;
}

.cancel-button {
  background-color: #f2f2f2;
  color: #3b8070;
}
.report-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.report-table th,
.report-table td {
  padding: 10px;
  text-align: left;
}

.report-table td {
  border-bottom: 1px solid #ddd;
}

.report-table i {
  font-size: 1.2em;
  margin: 0 5px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.report-table i:nth-child(1) {
  color: #136e66;
}

.report-table i:nth-child(2) {
  color: #c82021;
}

.report-table i:nth-child(3) {
  color: #136e66;
}

.report-table i:hover {
  color: #000;
  transform: scale(1.2);
}

.report-table td .icon-container {
  display: flex;
  gap: 10px;
}

.report-table td {
  position: relative;
}

.icon-container i {
  font-size: 1.2em;
  color: #000;
}
</style>

