import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles/global.css'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueTheMask from 'vue-the-mask';
import VueMoney from 'v-money';

const app = createApp(App);

app.use(router);
console.log(process.env.VUE_APP_API_BASE_URL)
app.use(VueSweetalert2);
app.use(VueTheMask);
app.use(VueMoney, { prefix: '', thousands: ',', decimal: '.' });
app.mount('#app');
