<template>
  <header class="navbar">
    <div class="navbar-brand">
      <a href="/home" :class="{ active: isActive('/home') }">
        <img src="/Logo.png" class="logo" />
      </a>
    </div>
    <div class="navbar-menu">
      <div class="navbar-brand">
        <a href="/novo-laudo" :class="{ active: isActive('/novo-laudo') }">Nova Avaliação</a>
      </div>
      <div class="navbar-brand">
        <a href="/dados" :class="{ active: isActive('/dados') }">Consulta Rápida</a>
      </div>
      <div class="navbar-brand">
        <a href="/consultarlaudos" :class="{ active: isActive('/consultarlaudos') }">Consultar Avaliações</a>
      </div>
    </div>
    <div class="navbar-name">
      <span style="margin-right: 10px;">{{userName}}</span>
      <logout-component />
    </div>
  </header>
</template>

<script>
import LogoutComponent from '@/components/ButtonsComponent.vue/LogoutComponent.vue';

export default {
  components: { LogoutComponent },
  data() {
   return {
    userName:''
   }
  },
  methods: {
    isActive(path) {
      return this.$route.path === path;
    },
    getUserName() {
      const storedUserName = sessionStorage.getItem('userName');
      
      if (!storedUserName) {
        this.$router.push({ name: 'login' });
      } else {
        this.userName = storedUserName;
      }
    }
  },
  created() {
    this.getUserName();
  }
}
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px; 
  background-color: #136E66; 
  color: white;
  height: 50px;
}

.navbar-menu {
  display: flex;
  align-items: center; 
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu div {
  margin: 0 20px; 
}

.navbar-menu a {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 18px; 
  transition: color 0.3s, border-bottom 0.3s;
}

.navbar-menu a.active {
  font-weight: bold;
  border-bottom: 2px solid #FFFFFF; 
}
.navbar-menu a:hover {
  color:  #D2AE6D; 
  border-bottom: 2px solid  #D2AE6D; 
}
.navbar-name {
  display: flex;
  align-items: center;
}

.navbar-name span {
  margin-right: 10px; 
}

.logo {
  max-height: 40px; 
  max-width: 80px;
}
</style>
