<template>
  <div class="form-container">
    <h1>Nova Avaliação Imobiliária – Cadastrar Avaliação</h1>
    <span>Etapa 1 - Informe os dados do imóvel que será avaliado</span>
    <form @submit.prevent="submitForm">
      <!-- Existing form elements -->
      <div class="form-group images">
        <!-- Image Upload Fields -->
        <div class="form-group-item">
          <label for="fachada">Foto da Fachada</label>
          <input
            type="file"
            @change="handleImageUpload($event, 'fachada')"
            accept="image/png, image/jpeg"
          />
        </div>
        <div class="form-group-item">
          <label for="localizacao">Foto da Localização</label>
          <input
            type="file"
            @change="handleImageUpload($event, 'localizacao')"
            accept="image/png, image/jpeg"
          />
        </div>
      </div>
      <div class="form-group address-group details-group">
        <!-- Address Inputs -->
        <div class="form-group-item">
          <label for="endereco">Nome do requerente</label>
          <input
            type="text"
            id="requerente"
            v-model="form.nome_requerente"
            maxlength="100"
            placeholder="Digite"
            required
          />
        </div>
        <div class="form-group-item">
          <label for="cpf">CPF do requerente</label>
          <input
            type="text"
            id="cpf"
            v-mask="'###.###.###-##'"
            v-model="form.cpf_requerente"
            placeholder="Digite o CPF"
            required
          />
        </div>
        <div class="form-group-item">
          <label for="endereco">Endereço</label>
          <input
            type="text"
            id="endereco"
            v-model="form.endereco"
            maxlength="100"
            placeholder="Digite"
          />
        </div>
        <div class="form-group-item">
          <label for="complemento">Complemento</label>
          <input
            type="text"
            id="complemento"
            v-model="form.complemento"
            placeholder="Digite"
            maxlength="20"
          />
        </div>
        <div class="form-group-item">
          <label for="matricula">Matrícula do Imóvel</label>
          <input
            type="text"
            id="matricula"
            v-model="form.matricula"
            placeholder="Digite"
            required
          />
        </div>
      </div>
      <div class="form-group type-group details-group">
        <!-- Type Inputs -->
        <div class="form-group-item">
          <label for="empreendimento">Empreendimento</label>
          <input
            type="text"
            id="empreendimento"
            v-model="form.empreendimento"
            placeholder="Digite"
            maxlength="50"
          />
        </div>
        <div class="form-group-item">
          <label for="uso">Uso</label>
          <select id="uso" v-model="form.uso" required>
            <option disabled value="">Selecione</option>
            <option value="Residencial">Residencial</option>
            <option value="Comercial">Comercial</option>
          </select>
        </div>
        <div class="form-group-item">
          <label for="tipologia">Tipologia</label>
          <select id="tipologia" v-model="form.tipologia" required>
            <option disabled value="">Selecione</option>
            <option value="Casa">Casa</option>
            <option value="Apartamento">Apartamento</option>
            <option value="Sobrado">Sobrado</option>
            <option value="Triplex">Triplex</option>
            <option value="Terreno">Terreno</option>
            <option value="Galpão">Galpão</option>
            <option value="Outros">Outros</option>
          </select>
        </div>
        <div class="form-group-item">
          <label for="condominio">Condomínio</label>
          <select id="condominio" v-model="form.condominio" required>
            <option disabled value="">Selecione</option>
            <option value="Sim">Sim</option>
            <option value="Não">Não</option>
          </select>
        </div>
        <div class="form-group-item">
          <label for="andar_pavimento">Andar/Pavimento</label>
          <input
            type="text"
            id="andar_pavimento"
            v-model="form.andar_pavimento"
            placeholder="Digite"
            maxlength="10"
          />
        </div>
        <div class="form-group-item">
          <label for="idade_imovel">Idade do Imóvel</label>
          <input
            type="number"
            id="idade_imovel"
            v-model.number="form.idade_imovel"
            placeholder="Digite"
            maxlength="3"
            min="0"
          />
        </div>
      </div>
      <div class="form-group details-group">
        <!-- Details Inputs -->
        <div class="form-group-item">
          <label for="estado_conservacao">Conservação</label>
          <select
            id="estado_conservacao"
            v-model="form.estado_conservacao"
            required
          >
            <option disabled value="">Selecione</option>
            <option value="Novo">Novo</option>
            <option value="Ótimo">Ótimo</option>
            <option value="Bom">Bom</option>
            <option value="Regular">Regular</option>
            <option value="Ruim">Ruim</option>
          </select>
        </div>
        <div class="form-group-item">
          <label for="total_dormitorios">Dormitórios</label>
          <input
            type="number"
            id="total_dormitorios"
            v-model.number="form.total_dormitorios"
            maxlength="2"
            min="0"
          />
        </div>
        <div class="form-group-item">
          <label for="total_bwc">Total BWC</label>
          <input
            type="number"
            id="total_bwc"
            v-model.number="form.total_bwc"
            placeholder="Digite"
            maxlength="2"
            min="0"
          />
        </div>
        <div class="form-group-item">
          <label for="vagas_garagem">Vagas Garagem</label>
          <input
            type="number"
            id="vagas_garagem"
            v-model.number="form.vagas_garagem"
            placeholder="Digite"
            maxlength="2"
            min="0"
          />
        </div>
        <div class="form-group-item">
          <label for="valor_avaliacao">Valor Declarado</label>
          <input
            type="text"
            id="valor_avaliacao"
            v-model="formattedValorAvaliacao"
            @input="updateValorAvaliacao"
            placeholder="Digite"
          />
        </div>
        <div class="form-group-item">
          <label for="area_m2">Área m²</label>
          <input
            type="number"
            id="area_m2"
            v-model="form.area_m2"
            placeholder="Digite"
            min="0"
            @input="calculateValorM2"
          />
        </div>
        <div class="form-group-item">
          <label for="valor_m2">Valor do m²</label>
          <input
            type="text"
            id="valor_m2"
            v-model="formattedValorM2"
            placeholder="Digite"
            disabled
          />
        </div>
      </div>
      <div class="button-container">
        <button
          type="button"
          class="cancel-button"
          @click="cancelAction"
          :disabled="loading"
        >
          Cancelar
        </button>
        <button type="submit" class="submit-button" :disabled="loading">
          Salvar e continuar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: ["value"],
  data() {
    return {
      form: {
        endereco: null,
        nome_requerente: null,
        cpf_requerente: null,
        complemento: null,
        matricula: null,
        empreendimento: null,
        uso: "",
        tipologia: "",
        condominio: "",
        andar_pavimento: null,
        idade_imovel: null,
        estado_conservacao: "",
        total_dormitorios: null,
        total_bwc: null,
        vagas_garagem: null,
        valor_avaliacao: null,
        area_m2: null,
        valor_m2: null,
        observacoes_adicionais: null,
        fundamento_legal: null,
        fiscal_id: "",
      },
      images: {
        fachada: null,
        localizacao: null,
      },
      isSubmitting: false,
    };
  },
  computed: {
    formattedValorAvaliacao() {
      return this.formatCurrency(this.form.valor_avaliacao);
    },
    formattedValorM2() {
      return this.form.valor_m2 ? this.formatCurrency(this.form.valor_m2) : "";
    },
  },
  created() {
    const fiscalId = sessionStorage.getItem("fiscal_id");
    if (fiscalId) {
      this.form.fiscal_id = fiscalId;
    }
  },
  methods: {
    handleImageUpload(event, key) {
      const file = event.target.files[0];

      if (file) {
        // Check file type
        const validTypes = ["image/png", "image/jpeg"];
        if (!validTypes.includes(file.type)) {
          alert("Por favor, selecione um arquivo PNG ou JPEG.");
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.images[key] = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    updateValorAvaliacao(event) {
      const rawValue = event.target.value.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
      this.form.valor_avaliacao = parseInt(rawValue, 10) || 0;

      this.calculateValorM2(); // Recalcula o valor por m²
    },

    formatCurrency(value) {
      if (isNaN(value)) return "";
      let number = (value / 100).toFixed(2).replace(".", ",");

      let [integerPart, decimalPart] = number.split(",");

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return `R$ ${integerPart},${decimalPart}`;
    },

    calculateValorM2() {
      if (
        this.form.valor_avaliacao &&
        this.form.area_m2 &&
        this.form.area_m2 > 0
      ) {
        const valorM2 = this.form.valor_avaliacao / this.form.area_m2;
        this.form.valor_m2 = valorM2;
      } else {
        this.form.valor_m2 = null;
      }
    },
    async cancelAction() {
      const result = await Swal.fire({
        title: "Atenção!",
        text: "Deseja realmente cancelar? Todos os dados não salvos serão perdidos.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, limpar dados!",
        cancelButtonText: "Não, desejo cancelar!",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          const cancelButton = Swal.getCancelButton();

          if (confirmButton) {
            confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 12px 24px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: auto !important; 
              height: auto !important;  
              display: inline-block;
              box-sizing: border-box; 
            `;
          }

          if (cancelButton) {
            cancelButton.style.cssText = `
              background-color: #F4F4F5 !important; 
              color: #18181B !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 12px 24px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: auto !important; 
              height: auto !important;  
              display: inline-block;
              box-sizing: border-box; 
            `;
          }
        },
      });

      if (result.isConfirmed) {
        this.clearForm();
        Swal.fire({
          title: "Cancelado!",
          text: "Dados removidos com sucesso!",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    },
    showAlert() {
      this.$swal({
        title: "Hello Vue!",
        text: "This is a SweetAlert2 alert!",
        icon: "success",
        confirmButtonText: "Cool",
      });
    },
    clearForm() {
      this.form = {
        endereco: "",
        complemento: "",
        matricula: "",
        empreendimento: "",
        uso: null,
        tipologia: null,
        condominio: null,
        andar_pavimento: "",
        idade_imovel: null,
        estado_conservacao: null,
        total_dormitorios: null,
        total_bwc: null,
        vagas_garagem: null,
        valor_avaliacao: null,
        area_m2: null,
        valor_m2: null,
        observacoes_adicionais: "",
        fundamento_legal: "",
        fiscal_id: "",
      };
      this.images = {
        fachada: null,
        localizacao: null,
      };
    },
    watch: {
      "form.idade_imovel"(newVal) {
        if (newVal < 0) this.form.idade_imovel = 0;
      },
      "form.total_dormitorios"(newVal) {
        if (newVal < 0) this.form.total_dormitorios = 0;
      },
      "form.total_bwc"(newVal) {
        if (newVal < 0) this.form.total_bwc = 0;
      },
      "form.vagas_garagem"(newVal) {
        if (newVal < 0) this.form.vagas_garagem = 0;
      },
      "form.valor_avaliacao"(newVal) {
        if (newVal < 0) this.form.valor_avaliacao = 0;
      },
      "form.area_m2"(newVal) {
        if (newVal < 0) this.form.area_m2 = 0;
      },
      "form.valor_m2"(newVal) {
        if (newVal < 0) this.form.valor_m2 = 0;
      },
    },

    formatValueForAPI(value) {
      if (typeof value === "string") {
        // Remove todos os caracteres não numéricos
        value = value.replace(/[^\d]/g, "");
      }

      // Converte o valor para número inteiro
      let number = parseInt(value, 10);

      // Se o número não for válido, retorna uma string vazia
      if (isNaN(number)) return "";

      // Converte o número em string e insere o ponto antes dos dois últimos dígitos
      const stringValue = number.toString();

      // Se o número tiver 2 ou mais dígitos, insere o ponto
      if (stringValue.length > 2) {
        return `${stringValue.slice(0, -2)}.${stringValue.slice(-2)}`;
      } else {
        // Caso o número seja menor que 2 dígitos, adiciona "0." antes
        return `0.${stringValue.padStart(2, "0")}`;
      }
    },

    async submitForm() {
      if (
        !this.form.uso ||
        !this.form.tipologia ||
        !this.form.condominio ||
        !this.form.estado_conservacao
      ) {
        alert("Por favor, selecione todos os campos obrigatórios.");
        return;
      }

      this.isSubmitting = true;

      // Cria o payload com os valores formatados
      const payload = {
        ...this.form,
        img_fachada: this.images.fachada,
        img_localizacao: this.images.localizacao,
        valor_avaliacao: this.formatValueForAPI(this.form.valor_avaliacao),
        valor_m2: this.formatValueForAPI(this.form.valor_m2),
      };

      console.log("Enviando:", payload);

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
            body: JSON.stringify(payload),
          },
        );

        if (!response.ok) {
          throw new Error("Erro ao criar o laudo");
        }

        const data = await response.json();

        if (data.laudoId) {
          sessionStorage.setItem("laudoID", data.laudoId);
          this.$emit("formSubmitted", this.form);
          this.$emit("nextStep");

          await Swal.fire({
            title: "Sucesso!",
            text: "Avaliação cadastrada com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        await Swal.fire({
          title: "Atenção!",
          text: "Ocorreu um erro ao cadastrar o laudo. Por favor, tente novamente.",
          icon: "warning",
          confirmButtonText: "Ok",
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            if (confirmButton) {
              confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
            }
          },
        });
      } finally {
        this.isSubmitting = false;
      }
    },

    nextPage() {
      this.$emit("next");
    },
  },
};
</script>

<style scoped>
.form-container {
  background-image: url("/public/Fundotela.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 94vh;
  padding: 15px;
  box-sizing: border-box;
}

h1 {
  font-size: 28px;
  color: #136e66;
  margin-bottom: 20px;
  text-align: left;
}

span {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  color: #4f4f4f;
  border-bottom: 1px solid #136e66;
  padding-bottom: 10px;
}

form {
  display: grid;
  gap: 10px;
  margin: 0 auto; /* Centraliza o formulário */
}

.form-group-item {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
}

input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

label {
  color: #09090b;
}

.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.images .form-group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images input[type="file"] {
  border: none;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  background-color: #f9f9f9;
  cursor: pointer;
}

.images label {
  margin-bottom: 10px;
  font-weight: bold;
}

.address-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.type-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 8px;
}

.details-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 6px;
}

.details-group .form-group-item {
  margin: 0;
}

.details-group input[type="text"],
.details-group input[type="number"],
.details-group select,
.details-group textarea {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
}

.details-group label {
  font-size: 14px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.submit-button,
.cancel-button {
  width: 231px;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button {
  color: #fff;
  background-color: #136e66;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  opacity: 0.8;
}

.cancel-button {
  color: #0e0101;
  background-color: #ffffff;
}

.cancel-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.cancel-button:hover:not(:disabled) {
  opacity: 0.8;
}
</style>

