<template>
  <div class="login-wrapper">
    <div class="logo-container">
      <img src="/Logo.png" class="logo">
    </div>
    <div class="login-container">
      <h2>ITBI</h2>
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <label for="cpf" class="label-left">CPF</label>
          <input type="text" v-model="cpf" id="cpf" placeholder="Digite seu CPF" v-mask="'###.###.###-##'" class="input-field" required />
        </div>
        <div class="input-group">
          <label for="password" class="label-left">Senha</label>
          <input type="password" v-model="password" id="password" placeholder="Digite sua senha" class="input-field" required />
        </div>
        <button type="submit">Acessar</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      cpf: '',
      password: ''
    };
  },
 methods: {
  async handleSubmit() {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/fiscal/login`, {
        cpf: this.cpf,
        password: this.password
      },
      {
      headers: {
        'x-access-token': process.env.VUE_APP_API_TOKEN, 
      }
      }
     );

      if (response.status === 200) {
        const { id, nome, email, cpf } = response.data;
        sessionStorage.setItem('fiscal_id', id);
        sessionStorage.setItem('userName', nome);
        sessionStorage.setItem('userEmail', email);
        sessionStorage.setItem('userCpf', cpf);
        sessionStorage.setItem('auth', 'true');

        this.$emit('login-success');
      } else {
        this.$emit('login-failure', 'Credenciais inválidas. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      if (error.response) {
        console.log('Erro de resposta da API:', error.response);
        if (error.response.status === 401) {
          this.$emit('login-failure', 'Credenciais inválidas. Por favor, tente novamente.');
        } else if (error.response.status >= 500) {
          this.$emit('login-failure', 'Erro no servidor. Tente novamente mais tarde.');
        } else {
          this.$emit('login-failure', 'Erro ao fazer login. Verifique suas credenciais.');
        }
      } else if (error.request) {
        console.log('Erro de requisição, sem resposta da API:', error.request);
        this.$emit('login-failure', 'Falha na conexão');
      } else {
        console.error('Erro inesperado ao fazer login:', error.message);
        this.$emit('login-failure', 'Erro inesperado. Tente novamente.');
      }
    }
  }
}

};
</script>

<style scoped>
.login-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/public/Login.png');
    background-position: center;
    background-size: cover;
    flex-direction: column; 
}

.logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 32px; 
}

.logo {
    width: 10%; 
    max-width: 135px; 
    height: auto;
}

.login-container {
    position: relative;
    width: 90%; 
    max-width: 436px; 
    padding: 20px;
    background: #ECFCFB;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

h2 {
    margin-bottom: 20px; 
    text-align: center;
    color: #136E66;
    font-size: 28px;
}

.label-left {
    text-align: left;
    font-size: 18px;
}

.input-group {
    margin-bottom: 32px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #3B8070;
    font-size: 18px;
}

input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    width: 100%;
    padding: 10px;
    background: #136E66;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 32px;
}

button:hover {
    background: #3a6f68;
}

</style>
