<template>
    <div class="header">
        <button @click="confirmSair" class="btn-sair">
            <i class="pi pi-sign-out" style="color: #fff"></i>
        </button>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import "primeicons/primeicons.css";

export default {
  methods: {
    async confirmSair() {
      const result = await Swal.fire({
        title: "Atenção!",
        text: "Você tem certeza que deseja sair? Todas as alterações não salvas serão perdidas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, sair!",
        cancelButtonText: "Não, ficar na página!",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          const cancelButton = Swal.getCancelButton();

          if (confirmButton) {
            confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 12px 24px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: auto !important; 
              height: auto !important;  
              display: inline-block; 
              box-sizing: border-box;
            `;
          }

          if (cancelButton) {
            cancelButton.style.cssText = `
              background-color: #F4F4F5 !important; 
              color: #18181B !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 12px 24px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: auto !important; 
              height: auto !important;  
              display: inline-block; 
              box-sizing: border-box;
            `;
          }
        }
      });

      if (result.isConfirmed) {
        this.sair();
      }
    },
    sair() {
      this.$router.push({ name: 'login' });
    }
  }
}
</script>


<style scoped>
.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.btn-sair {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #136E66;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-sair i {
  margin-right: 8px;
}

.btn-sair:hover {
  background-color: #D2AE6D;
}
</style>