<template>
     <button @click="exportToCSV" class="export-button">
      <i class="fa-solid fa-upload"></i> Exportar CSV
    </button>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    exportToCSV() {
      if (this.data.length === 0) {
        alert('Nenhum item selecionado para exportar.');
        return;
      }

      const csvContent = this.convertToCSV(this.data);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', 'Dados.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertToCSV(objArray) {
      // Aqui está o método atualizado
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = '';

      const headers = this.$parent.columns
        .filter(column => this.$parent.selectedColumns.includes(column.key))
        .map(column => column.label);
      
      row = headers.map(header => `"${header.replace(/"/g, '""')}"`).join(',');
      str += row + '\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const column of this.$parent.columns.filter(column => this.$parent.selectedColumns.includes(column.key))) {
          const value = array[i][column.key] || '';
          if (line !== '') line += ',';
          line += `"${value.toString().replace(/"/g, '""')}"`;
        }
        str += line + '\r\n';
      }
      return str;
    }
  }
};
</script>

<style scoped>
button {
  padding: 8px 12px;
  background-color: #3B8070;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #356f63;
}
</style>
