<template>
  <div class="container">
    <div class="login-view">
      <UserLogin
        @login-success="handleLoginSuccess"
        @login-failure="handleLoginFailure"
      />
    </div>
    <div class="error-box" v-if="errorMessage">
      <p class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import UserLogin from "../components/UserLogin.vue";

export default {
  name: "LoginView",
  components: {
    UserLogin,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
    handleLoginSuccess() {
      this.$router.push("/home");
    },
    handleLoginFailure(message) {
      this.errorMessage = message;
      console.log('Mensagem de erro:', message);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; 
}

.login-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.error-box {
  color: red;
  padding: 10px;
  border: 1px solid red;
  background-color: #fdd;
  border-radius: 4px;
  margin-bottom: 10px; 
  position: absolute; 
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 10; 
}

.error-message {
  margin: 0;
  font-size: 14px;
}

.login-view .user-login {
  margin-top: 20px; 
}
</style>
