
 <template>
  <menu-component />
  <div>
    <div v-if="activeTab === 'form'">
      <form-component @formSubmitted="handleFormSubmitted" @next="nextTab" />
    </div>
    <div v-if="activeTab === 'laudo'">
      <laudo-component @back="backToForm" />
    </div>

    <table-component
      v-if="activeTab === 'table'"
      :value="selectedItems"
      @updateSelectedItems="updateSelectedItems"
      @back="backToForm"
    />
  </div>
</template>

<script>
/* eslint-disable */
import FormComponent from "./FormComponent.vue";
import LaudoComponent from "./LaudoComponent.vue";
import MenuComponent from "@/components/layoutComponent.vue/MenuComponent.vue";

export default {
  components: { FormComponent, LaudoComponent, MenuComponent },
  props: ["reportId"],
  data() {
    return {
      activeTab: "form",
      formData: {},
      laudo: {
        valorAvaliacao: "",
        valorM2: "",
        itensSelecionados: [],
      },
      images: {
        fachada: "",
        localizacao: "",
      },
    };
  },
  mounted() {
    if (this.reportId) {
      this.loadReportData(this.reportId);
    }
    const fiscalId = sessionStorage.getItem("fiscal_id");
    if (fiscalId) {
      this.formData.fiscal_id = fiscalId;
    }
  },
  methods: {
    handleFormSubmitted(formData) {
      this.formData = formData;
      this.nextTab();
    },
    updateSelectedItems(selectedIds) {
      this.laudo.itensSelecionados = this.getDetailedItems(selectedIds);
    },
    nextTab() {
      this.activeTab = "laudo";
    },
    backToForm() {
      this.activeTab = "form";
    },
    async loadReportData(reportId) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        const data = await response.json();

        console.log("Dados do relatório:", data);

        this.laudo = {
          valorAvaliacao: data.valor_avaliacao,
          valorM2: data.valor_m2,
          itensSelecionados: data.itens_selecionados,
        };
        this.formData = {
          endereco: data.endereco,
          complemento: data.complemento,
          matricula: data.matricula,
          empreendimento: data.empreendimento,
          uso: data.uso,
          tipologia: data.tipologia,
          condominio: data.condominio,
          andar_pavimento: data.andar_pavimento,
          idade_imovel: data.idade_imovel,
          estado_conservacao: data.estado_conservacao,
          total_dormitorios: data.total_dormitorios,
          total_bwc: data.total_bwc,
          vagas_garagem: data.vagas_garagem,
          observacoes_adicionais: data.observacoes_adicionais,
          fundamento_legal: data.fundamento_legal,
          fiscal_id: data.fiscal_id,
        };

        console.log("URL da imagem da fachada:", data.url_img_fachada);
        console.log("URL da imagem da localização:", data.url_img_localizacao);

        this.images.fachada = data.url_img_fachada || "";
        this.images.localizacao = data.url_img_localizacao || "";
      } catch (error) {
        console.error("Erro ao carregar os dados do laudo:", error);
      }
    },
  },
};
</script>

<style scoped>
.submit-button-pdf {
  background-color: #3b8070;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.submit-button-pdf:hover {
  background-color: #356f63;
}
</style>
